export const SHORT_DAY = 'ddd'
export const DAY = 'dddd'
export const DAY_MONTH_FORMAT = 'DD.MM'
export const DATE_FORMAT = 'DD-MM-YYYY'
export const TIME_FORMAT = 'HH:mm'
export const TIME_FORMAT_WITH_SECONDS = 'HH:mm:ss'
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const DATE_TIME_FORMAT_WITH_SECONDS = 'DD-MM-YYYY HH:mm:ss'

export const API_DATE_FORMAT = 'YYYY-MM-DD'
export const API_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'

export const RESULT_API_DATE_TIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ssZ'
export const RESULT_API_EXTENDED_DATE_TIME_FORMAT =
  'YYYY-MM-DD[T]HH:mm:ss.SSSSSSZ'

export const LONG_DATE_FORMAT = 'dddd, Do of MMMM, YYYY' // Thursday, 17th of October, 2021

export const DATE_FORMATS_TO_PARSE = [
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  API_DATE_FORMAT,
  API_DATE_TIME_FORMAT,
  RESULT_API_DATE_TIME_FORMAT,
  RESULT_API_EXTENDED_DATE_TIME_FORMAT,
  LONG_DATE_FORMAT
]

export const TIME_FORMATS_TO_PARSE = [TIME_FORMAT, TIME_FORMAT_WITH_SECONDS]
