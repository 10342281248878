import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

const examSessionChildrenRoute = [
  {
    path: '',
    name: 'exam-sessions-list',
    component: () => import('@/views/ExamPlanner/ExamSessionList/index.vue'),
    meta: {
      title: 'Exam sessions',
      tab: 'administration'
    }
  },
  {
    path: ':id',
    component: () => import('@/views/ExamPlanner/ExamSessionDetails/index.vue'),
    name: 'exam-session-details',
    meta: {
      title: 'Exam planner',
      tab: 'administration',
      permissions: ['exam_planner.view_exam']
    }
  }
] satisfies Route[]

const examPlannerChildrenRoutes = [
  {
    path: 'exam-sessions',
    name: 'exam-session',
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Exam planner',
      tab: 'administration',
      permissions: ['exam_planner.view_examsession']
    },
    redirect: () => getFirstPossibleRoute(examSessionChildrenRoute),
    children: examSessionChildrenRoute
  },
  {
    path: 'calendar/:id',
    component: () => import('@/views/ExamPlanner/ExamsPreview/index.vue'),
    name: 'exam-session-timetable',
    meta: {
      title: 'Exam timetable',
      tab: 'administration',
      permissions: ['exam_planner.view_examsession']
    }
  }
] satisfies Route[]

export const examPlannerRoutes = [
  {
    path: 'exam-planner',
    name: 'exam-planner' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Exam planner',
      icon: 'calendar',
      tab: 'administration'
    },
    redirect: () => getFirstPossibleRoute(examPlannerChildrenRoutes),
    children: examPlannerChildrenRoutes
  }
] satisfies Route[]
