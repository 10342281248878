import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

export const doctorsChildrenRoutes = [
  {
    path: '',
    name: 'doctor-list' as const,
    component: () => import('@/views/Health/DoctorList/DoctorListView.vue'),
    meta: {
      title: 'Doctors List',
      tab: 'administration',
      permissions: ['doctors.change_doctor']
    }
  }
] satisfies Route[]

export const doctorsRoutes = [
  {
    path: 'doctors',
    name: 'doctors' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Doctors',
      icon: 'user',
      tab: 'administration',
      permissions: ['doctors.view_doctor']
    },
    redirect: () => getFirstPossibleRoute(doctorsChildrenRoutes),
    children: doctorsChildrenRoutes
  }
] satisfies Route[]
