import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

export const attendanceTabRoutes = [
  {
    path: 'attendance-reports',
    name: 'attendance-report-list' as const,
    component: () =>
      import(
        '@/views/Attendance/AttendanceReportList/AttendanceReportView.vue'
      ),
    meta: {
      icon: 'calendar',
      title: 'Attendance report',
      tab: 'boarding',
      permissions: ['boarding_attendances.view_boardingattendancereport']
    }
  },
  {
    path: 'campus-absences',
    name: 'campus-absence-list' as const,
    component: () =>
      import('@/views/Attendance/CampusAbsence/CampusAbsenceView.vue'),
    meta: {
      icon: 'carry-out',
      title: 'Campus absence',
      tab: 'boarding',
      permissions: ['campus_absences.view_campusabsence']
    }
  },
  {
    path: 'leaves',
    name: 'leave-request-list' as const,
    component: () =>
      import('@/views/Attendance/LeaveRequest/LeaveRequestView.vue'),
    meta: {
      icon: 'schedule',
      title: 'Leave requests',
      tab: 'boarding',
      permissions: ['leaves.view_leaverequest']
    }
  },
  {
    path: 'night-attendance-reports',
    name: 'night-attendance-report-list' as const,
    component: () =>
      import(
        '@/views/Attendance/NightRoomingReport/NightRoomingReportView.vue'
      ),
    meta: {
      icon: 'home',
      title: 'Night rooming',
      tab: 'boarding',
      permissions: ['boarding_night_attendances.view_nightattendancereport']
    }
  }
] satisfies Route[]

const attendanceNotTabRoutes = [
  {
    path: '',
    name: 'attendance-tab' as const,
    component: () => import('@/components/base/BaseLayout.vue'),
    props: {
      routes: attendanceTabRoutes
    },
    meta: {
      tab: 'boarding',
      title: 'Attendance'
    },
    redirect: () => getFirstPossibleRoute(attendanceTabRoutes),
    children: attendanceTabRoutes
  },
  {
    path: 'attendance-reports/:id',
    name: 'attendance-report-details' as const,
    component: () =>
      import('@/views/Attendance/AttendanceReportDetails/index.vue'),
    meta: {
      title: 'Boarding Attendances Reports Details',
      tab: 'boarding',
      permissions: ['boarding_attendances.view_boardingattendancereport']
    }
  },
  {
    path: 'night-attendance-reports/:id',
    name: 'night-attendance-report-details' as const,
    component: () =>
      import('@/views/Attendance/NightRoomingReportDetails/index.vue'),
    meta: {
      title: 'Boarding Attendances Reports Details',
      tab: 'boarding',
      permissions: ['boarding_night_attendances.view_nightattendancereport']
    }
  }
] satisfies Route[]

export const attendanceRoutes = [
  {
    path: 'attendance',
    name: 'attendance' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Attendance',
      tab: 'boarding',
      icon: 'calendar'
    },
    redirect: () => getFirstPossibleRoute(attendanceNotTabRoutes),
    children: attendanceNotTabRoutes
  }
] satisfies Route[]
