import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

export const roomManagementTabRoutes = [
  {
    path: 'student-accommodation',
    name: 'student-accommodation' as const,
    component: () =>
      import(
        '@/views/RoomManagement/StudentAccommodation/StudentAccommodationView.vue'
      ),
    meta: {
      icon: 'calendar',
      title: 'Student accommodation',
      tab: 'boarding',
      permissions: ['boarding_accommodations.view_accommodation']
    }
  },
  {
    path: 'room-booking',
    name: 'room-booking' as const,
    component: () =>
      import('@/views/RoomManagement/RoomBooking/RoomBookingView.vue'),
    meta: {
      icon: 'book',
      title: 'Room booking',
      tab: 'boarding',
      permissions: ['houses.view_houseroom']
    }
  }
] satisfies Route[]

export const roomManagementRoutes = [
  {
    name: 'room-management',
    path: 'room-management' as const,
    component: () => import('@/components/base/BaseLayout.vue'),
    props: {
      routes: roomManagementTabRoutes
    },
    meta: {
      title: 'Room management',
      tab: 'boarding',
      icon: 'home',
      permissions: ['boarding_accommodations.view_accommodation']
    },
    redirect: () => getFirstPossibleRoute(roomManagementTabRoutes),
    children: roomManagementTabRoutes
  }
] satisfies Route[]
