import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

export const documentForAppsChildrenRoutes = [
  {
    path: '',
    name: 'documents-for-app-list' as const,
    component: () =>
      import('@/views/DocumentsForApps/DocumentsForAppsView.vue'),
    meta: {
      title: 'Documents for apps',
      tab: 'administration',
      permissions: ['files_for_students.view_fileforstudents']
    }
  }
] satisfies Route[]

export const documentForAppsRoutes = [
  {
    path: 'files-for-students',
    name: 'documents-for-app' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Documents for apps',
      icon: 'container',
      tab: 'administration'
    },
    redirect: () => getFirstPossibleRoute(documentForAppsChildrenRoutes),
    children: documentForAppsChildrenRoutes
  }
] satisfies Route[]
