<template>
  <div>
    <PageSizeSelect
      :pagination="pagination"
      :default-page-size="defaultPageSize"
    />
    <base-list
      v-bind="{
        ...$props,
        ...$attrs,
        ...$slots,
        ...$scopedSlots,
        ...$listeners
      }"
      :pagination="false"
    />
    <a-row
      v-if="pagination && pagination.pageSize"
      class="pagination"
      type="flex"
      align="middle"
      justify="end"
    >
      <BackToTopBtn v-if="!hideBackToTop" class="back-to-top" />
      <CustomPagination :pagination="pagination" />
    </a-row>
  </div>
</template>
<script>
import { List } from 'ant-design-vue'

import BackToTopBtn from './BackToTopBtn'
import CustomPagination from './CustomPagination'
import PageSizeSelect from './PageSizeSelect'

export default {
  components: {
    baseList: List,
    PageSizeSelect,
    BackToTopBtn,
    CustomPagination
  },
  props: {
    pagination: {
      type: [Boolean, Object],
      required: false
    },
    defaultPageSize: {
      type: Number,
      required: false
    },
    hideBackToTop: {
      default: false,
      type: Boolean
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination {
  padding: 16px 0;
}

::v-deep .arrow-icon {
  transition: 0.3s transform;

  &--rotated {
    transform: rotate(-180deg);
  }
}

.back-to-top {
  margin-right: 16px;
}
</style>
