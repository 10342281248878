import { usePermissions } from '@/composables/usePermissions'
import { type Route } from '@/types/route'

export const filterRoutesWithPermissions = (routes: Route[]) =>
  routes.reduce((routes: Route[], route) => {
    const { hasPermission } = usePermissions()
    const isValid =
      !route.meta?.permissions ||
      (route.meta.permissions &&
        !!route.meta.permissions.filter(hasPermission).length)

    if (!isValid) return routes
    else if (!route.children) return [...routes, route]
    const children: Route[] = filterRoutesWithPermissions(route.children)
    return children.length ? [...routes, { ...route, children }] : routes
  }, [])
