import { type Location } from 'vue-router'

import { type Tab } from '@/types/route'

export const TABS: Record<Tab, string> = {
  dashboard: 'dashboard',
  academics: 'academics',
  boarding: 'boarding',
  administration: 'administration',
  students: 'students'
}

export type TabDetails = {
  count?: number
  defaultRoute?: Location
  history?: Location
  name?: Tab | string
  title: string
}

export const tabs: Record<Tab, TabDetails> = {
  dashboard: {
    name: 'dashboard',
    title: 'My dashboard',
    count: 0,
    history: undefined
  },
  academics: {
    name: 'academics',
    title: 'Academics',
    history: undefined
  },
  boarding: {
    name: 'boarding',
    title: 'Boarding',
    history: undefined
  },
  administration: {
    name: 'administration',
    title: 'Administration',
    history: undefined
  },
  students: {
    name: 'students',
    title: 'Students'
  }
}
