import { TABS } from './tabs'

const adminRoutes = [
  {
    path: '/admin',
    redirect: '/admin/timetable/teacher',
    component: () => import('@/views/Layouts/RouterView.vue'),
    children: [
      {
        name: 'Timetable Teacher',
        path: 'timetable/teacher',
        component: () =>
          import(
            '@/views/AdminPanel/Timetable/Teacher/TeacherTimetableView.vue'
          ),
        meta: {
          title: 'Artisan timetable',
          tab: TABS.academics,
          permissions: ['permissions.view_teachers_timetable']
        }
      },
      {
        name: 'Timetable Student',
        path: 'timetable/student',
        component: () =>
          import(
            '@/views/AdminPanel/Timetable/Student/StudentTimetableView.vue'
          ),
        meta: {
          title: 'Student timetable',
          tab: TABS.academics,
          permissions: ['permissions.view_students_timetable']
        }
      },
      {
        name: "Artisans' covers",
        path: 'covers',
        component: () =>
          import('@/views/Academics/ArtisansCovers/ArtisansCoversView.vue'),
        meta: {
          title: "Artisans' covers",
          tab: TABS.academics,
          permissions: ['permissions.view_cover']
        }
      },
      {
        name: 'Absences',
        path: 'absence',
        component: () => import('@/views/AdminPanel/Absence/AbsencesView.vue'),
        meta: {
          title: 'Absences',
          tab: TABS.academics,
          permissions: ['attendances.view_absence']
        }
      }
    ]
  }
]

export default adminRoutes
