import Vue from 'vue'
import { VNode } from 'vue/types/umd'

type NotificationOptions = {
  duration?: number
  class?: string
  key?: string
  icon?: () => VNode
  description?: string | (() => VNode)
}

import { DEFAULT_ERROR_MESSAGE } from '@/constants/error-messages'

export function errorNotification(errorText?: string) {
  Vue.prototype.$notification.error({
    message: errorText || DEFAULT_ERROR_MESSAGE
  })
}

export function successNotification(
  successText: string,
  options?: NotificationOptions
) {
  Vue.prototype.$notification.success({
    message: successText,
    ...options
  })
}

export function infoNotification(
  successText: string,
  options?: NotificationOptions
) {
  Vue.prototype.$notification.info({
    message: successText,
    ...options
  })
}

export function warningNotification(
  warningText: string,
  options?: NotificationOptions
) {
  Vue.prototype.$notification.warning({
    message: warningText,
    ...options
  })
}
