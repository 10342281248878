<template>
  <BaseButton type="link" class="back-to-top" @click="scrollToTop">
    Back to top
    <a-icon type="arrow-up" />
  </BaseButton>
</template>

<script setup lang="ts">
import BaseButton from '@/components/base/BaseButton.vue'

const scrollToTop = () => {
  const contentWrapper = document.getElementById('content-wrapper')
  contentWrapper?.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>

<style lang="scss" scoped>
.back-to-top {
  font-weight: 400;
  color: var(--light-gray-color);

  &:hover {
    color: var(--black-color);
  }
}
</style>
