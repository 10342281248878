import { Route } from '@/types/route'

export const dashboardTabRoutes: Route[] = [
  {
    path: 'tasks',
    name: 'dashboard-tasks',
    component: () => import('@/views/Dashboard/Tasks/Tasks.vue'),
    meta: {
      title: 'Tasks',
      tab: 'dashboard'
    }
  }
]

export const dashboardRoutes: Route[] = [
  {
    name: 'dashboard',
    path: 'dashboard',
    component: () => import('@/views/Dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      tab: 'dashboard'
    },
    children: dashboardTabRoutes
  }
]
