import { getFirstPossibleRoute } from '@/router/utils/getFirstPossibleRoute'
import { type Route } from '@/types/route'

const housesChildrenRoutes = [
  {
    path: '',
    name: 'houses-list' as const,
    component: () => import('@/views/HouseList/HouseListView.vue'),
    meta: {
      title: 'Houses list',
      tab: 'administration',
      permissions: ['houses.change_house']
    }
  },
  {
    path: 'add',
    name: 'house-add',
    component: () => import('@/views/HouseForm/index.vue'),
    meta: {
      title: 'Create house',
      tab: 'administration',
      permissions: ['houses.add_house']
    }
  },
  {
    path: ':houseId',
    name: 'house-edit',
    component: () => import('@/views/HouseForm/index.vue'),
    meta: {
      title: 'Edit house',
      tab: 'administration',
      permissions: ['houses.change_house']
    }
  }
] satisfies Route[]

export const housesRoutes = [
  {
    path: 'houses',
    name: 'houses' as const,
    component: () => import('@/views/Layouts/RouterView.vue'),
    meta: {
      title: 'Houses',
      icon: 'home',
      tab: 'administration'
    },
    redirect: () => getFirstPossibleRoute(housesChildrenRoutes),
    children: housesChildrenRoutes
  }
] satisfies Route[]
