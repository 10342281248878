import { computed } from 'vue'

import store from '@/store/index'
import { Permission } from '@/types/permission'

type UserProfile = 'admin' | 'BoardingDirector' | 'PropertyManager'

export function usePermissions() {
  const userInfo = computed(() => store.state.userInfo)

  const userPermissions = computed(
    () => userInfo.value.permissions as Permission[]
  )

  const userProfiles = computed(() => userInfo.value.profiles as string[])

  const isSuperUser = computed(() => userInfo.value.is_superuser)

  const hasSomePermission = (permissions?: Permission[]) =>
    !permissions?.length ||
    userPermissions.value.some(permission => permissions.includes(permission))

  const hasPermission = (permission: Permission) =>
    !permission || userPermissions.value.includes(permission)

  const isAdmin = computed(() => isUserProfile('admin') || isSuperUser.value)

  const isUserProfile = (profile: UserProfile) =>
    userProfiles.value.includes(profile)

  return {
    hasPermission,
    hasSomePermission,
    isAdmin,
    isSuperUser,
    isUserProfile,
    userInfo,
    userProfiles
  }
}
